import React from 'react';
import { Card } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const MembersScreen = () => {
  return (
    <div>
      <h3>Directiva</h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/lalut.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Cristian Lalut</Card.Title>
              <Card.Text>Presidente</Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/nachovp.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Ignacio Mondaca</Card.Title>
              <Card.Text>Vice Presidente</Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/joseluis.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Jose Luis Ortiz</Card.Title>
              <Card.Text>Sgto de Armas</Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/coqueto.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Fabian Muñoz</Card.Title>
              <Card.Text>Cap. Ruta</Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/julio.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Julio Valdivia</Card.Title>
              <Card.Text>Tesorero</Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/titan.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Titan Vargas</Card.Title>
              <Card.Text>Secretario</Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <h3>Fullpatch</h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/ronaldo.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Ronaldo Lara</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/carlos.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Carlos Lincuñir</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/diego.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Diego Villa</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/felix.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Felix Quiroga</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/jp.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Jp</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/mauro.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Mauro Senn</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/pancho.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Francisco Gonzalez</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/rodrigo.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Rodrigo Navarrete</Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>

      <h3>Prospects</h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/ignacioainol.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Ignacio Ainol</Card.Title>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <Card style={{ width: '18rem', margin: '0 auto' }}>
            <LazyLoadImage
              effect="blur"
              src="/assets/images/integrants/alex.jpeg"
              className="card-img-top"
            />
            <Card.Body>
              <Card.Title>Alex Reyes</Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
